export const NativeTranslationModule = {
  presentAsync: async (_: string) => {},
}

export function NativeTranslationView() {
  return null
}

export const isAvailable = false

export function isLanguageSupported(_lang?: string) {
  return false
}
